/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

:host(.button-full) {
    display: block;
}

.ion-color-danger {
    --ion-color-base: violet !important;
    --ion-color-base-rgb: var(--ion-color-primary-rgb, 56, 128, 255) !important;
    --ion-color-contrast: var(--ion-color-primary-contrast, #fff) !important;
    --ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb, 255, 255, 255) !important;
    --ion-color-shade: violet!important;
    --ion-color-tint: violet!important;
}
.ion-color-primary {
    --ion-color-base: #c12868 !important;
    --ion-color-base-rgb: var(--ion-color-primary-rgb, 56, 128, 255) !important;
    --ion-color-contrast: var(--ion-color-primary-contrast, #fff) !important;
    --ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb, 255, 255, 255) !important;
    --ion-color-shade: #c12868!important;
    --ion-color-tint: #c12868!important;
}
// ion-button::part(native) {
//     background: #c12868;
// }
body{
    font-family: 'Montserrat', sans-serif;
    ion-card{
        margin: 0;
        box-shadow: none;
        border: none;
    }
} 

.toolbar-background {
    background: #c12869;
}

ion-tab-button {
    color: #7b7b7b;
}

.section-padding{
    padding:10px;
}

.section-gray{
    background-color: #FAFAFA;
}
hr {
    height: 1px;
    border-width: 0;
    box-sizing: content-box;
    margin: 15px 0px;
    border-top: 1px solid #e1e1e1;
}

#car-listings{
    ion-card * {
        padding: 0px!important;
    }
}

div#btn-back {
    position: absolute;
    font-size: 31px;
    top: 13px;
    left: 5px;
    color: white;
}
div#btn-back + ion-title {
    margin-left: 45px;
    color: white;
    padding: 0px !important;
    text-align: left;
    width: 80%;
}
ion-list.ion-list-common ion-icon {
    font-size: 26px;
    margin-right: 9px;
    color: var(--carbnb-primary-color);
}

.loading-container {
    text-align: center;
    margin: 30px;
    ion-spinner {
        transform: scale(2.5);
    }
}

.hyperlink{
    text-decoration: underline;
    font-weight: bolder;;
}
p.vehicle-capacity {
    text-transform: capitalize;
}

div#geo-locator-container {
    padding: 20px;
}

common-heading {
    padding: 5px;
    text-transform: uppercase;
    font-weight: bolder;
    display: block;
    color: #333;
    margin-bottom: 10px;
    padding: 15px;
    text-align: left;
    border-left: 7px solid #333;
    background: #FAFAFA;
}

ion-tab-button {
    font-size: 11px;
}
// .platform-ios body {
//     padding-top: constant(safe-area-inset-top); /* Status bar height on iOS 11.0 */
//     padding-top: env(safe-area-inset-top); /* Status bar height on iOS 11+ */
// }

ion-tab-bar,
ion-tab-button {
    background: #C22868;
    color:white;
    font-size: 11px;
    height: 70px;
}
ion-item .label-stacked {
    color: #c12868!important;
}

.listing-item {
    background: white;
    padding: 10px;
    border-radius: 10px;
    margin: 0 0 40px;
}

.home-page-container{
    background: #C2A9AB;
    padding: 10px;
}   


.favorite-container {
    position: absolute;
    right: 60px;
    top: 60px;
    z-index: 9999;
    font-size: 50px;
    color: white;
}
.favorite-container:after {
    content: 'Favorite';
    display: block;
    font-size: 9px;
    text-align: center;
    position: relative;
    top: -13px;
    text-transform: uppercase;
}
.favorite-container.active {
    color: #C12869;
}

.operator-name-container {
    font-size: 60px;
    h2 {
        padding-left: 10px;
        color: black!important;
        font-weight: 700;
    }
    ion-row {
        color: #c12868;
    }
}



igc-rating {
   font-size: 44px;
    color: orange;;
}

// igc-rating[disabled] {
//     --symbol-full-color: hsla(var(--igc-gray-400), var(--igc-gray-a));
//     --symbol-empty-color: hsla(var(--igc-gray-400), var(--igc-gray-a));
// }

// igc-rating::part(label) {
//     font-size: 18px;
//     font-weight: 600;
//     text-transform: var(--igc-overline-text-transform);
//     color: hsla(var(--igc-gray-600), var(--igc-gray-a));
// }

// igc-rating::part(symbols) {
//     gap: 8px; 
// }

swiper-slide {
    border-radius: 10px;
    overflow: hidden;
}

app-listing-item .image-container ion-img::part(image) {
    border-radius: 10px;
}

.vehicle-operator ion-icon {
    color: #222222;
}

.homepage-search {
    background: white;
    margin: 0 10px;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 5px;
    .searchbar-input{
        border-radius: 5px!important;
    }
}

.ion-page {
    padding-bottom: 100px!important;
}
ion-header.md {
    ion-toolbar:first-child {
        --padding-top: 27px;
        height: 66px;
    }
}

.login-container{
    img{
        margin-bottom: 30px;
    }
    max-width: 600px;
    margin: 0 auto;
}

.vehicle-economy {
    background: #7AB841;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    text-align: center;
    color: white;
    padding: 2px;
    font-size: 9px;
    border: 1px solid green;
    border-left: 6px solid green;
    display: inline;
    padding: 5px 13px;
    position: relative;
    top: 5px;
    left: 5px;
    font-weight: bold;
    box-shadow: 0 0 2px 1px #c7c7c7;
}

.hot-deals-container {
    font-size: 11px;
    background: white;
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
    text-align: center;
    color: #2c3e50;
    padding: 2px;
    border-radius: 5px;
    border: 3px dotted #2c3e50;
    margin-top: 10px;
}

.hot-deals-container .icon {
    position: relative;
    top: 1px;
    color: #2c3e50;
}

.hot-deals-container .icon ion-icon {
    color: red!important;
}


.heart {
    animation: 5s ease 0s infinite beat;
  }
  
  @keyframes beat {
    0%, 50%, 100% { transform: scale(1.5, 1.5) rotate(-5deg); }
    30%, 80% { transform: scale(0.92, 0.95) rotate(5deg); }
  }
  
  @keyframes pulse {
    0%, 50%, 100% {  background: #fee; }
    30%, 80% { background: #fff; }
  }